import { template as template_96de2453cbf0428389133845c6d6e2d0 } from "@ember/template-compiler";
const FKLabel = template_96de2453cbf0428389133845c6d6e2d0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
